<template>
    <v-sheet>
        <v-row class="mt-5 align-baseline">
            <v-col class="title ml-3">관리자 목록 ({{adminCount}}/{{adminMaxCount}})</v-col>
            <v-col class="text-right">
                <v-btn outlined large rounded class="text-h6" color="grey" @click="openAddPopup" v-if="adminCount < adminMaxCount"><span style="color:#333333">일반관리자 추가</span></v-btn>
            </v-col>
        </v-row>
        <v-card flat class="rounded-xl">
            <v-card-text>
                <v-simple-table class="border-table">
                    <template v-slot:default>
                    <thead>
                        <tr style="background-color:#F4F6F8; height:60px; color:#333333; font-weight:600;">
                            <th class="text-center text-h6">아이디</th>
                            <th class="text-center text-h6">이름</th>
                            <th class="text-center text-h6">전화번호</th>
                            <th class="text-center text-h6">관리</th>
                            <th class="text-center text-h6">상태</th>
                        </tr>
                    </thead>
                    <tbody style="color:#333333;">
                        <template v-for="(adminItem, adminIdx) in adminUserList">
                        <tr style="height:68px;" :key="'admin_' + adminIdx">
                            <td class="text-center text-h6">{{adminItem.admin_user_id == '[NOT_REG]' ? '미가입' : adminItem.admin_user_id}}<v-chip small color="green" outlined class="ml-3" v-if="adminItem.main_admin_yn == 'Y'">대표관리자</v-chip>
                            <td class="text-center text-h6">{{adminItem.admin_user_name}}</td>
                            <td class="text-center text-h6">{{adminItem.admin_user_mdn | phoneNum}}</td>
                            <td class="text-center text-h6">
                                <v-btn icon @click="delAdmin(adminItem)" v-if="adminItem.main_admin_yn != 'Y' && mainAdminYn == 'Y'" title="삭제"><v-icon>mdi-delete-outline</v-icon></v-btn>
                                <v-btn icon @click="giveAdmin(adminItem)" v-if="adminItem.main_admin_yn != 'Y' && adminItem.admin_reg_yn == 'Y' && mainAdminYn == 'Y'" title="권한위임"><v-icon>mdi-star</v-icon></v-btn>
                            </td>
                            <td class="text-center text-h6">{{adminItem.admin_reg_yn == 'Y' ? '' : '미가입'}}</td>
                        </tr>
                        </template>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-dialog v-model="modal" persistent max-width="500" @keydown.esc="closePopup()">
                <v-flex v-if="dialogMode === 'add'">
                    <popup-add v-on:submit="doSubmitAdd" v-on:cancel="closePopup"></popup-add>
                </v-flex>
            </v-dialog>
        </v-card>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'
import adminApi from '@/api/admin'

export default {
    name: 'admin_list',
    components: {
        'popupAdd': () => import('@/components/admin/popup_add.vue'),
    },
    data: () => ({
        adminMaxCount: 4,
        adminList: [            
            { 
                admin_user_id: 'callgate',
                admin_user_email: 'pbear@callgate.co.kr',
                admin_user_mdn: '01012345678',
                admin_user_name: '관리자',
                main_admin_yn: 'Y',
            },
        ],
        modal: false,
        dialogMode: 'none'
    }),
    created () { 
    },
    computed: {
        ...mapGetters({
            adminUserList: 'admin/getAdminUserList',
            mainAdminYn: 'login/getMainAdminYn',
        }),
        adminCount () { 
            let l = this.adminUserList
            return (l ? l.length : 0)
        }
    },  
    methods : { 
        openAddPopup () { 
            if(this.adminCount >= this.adminMaxCount) { return }

            this.dialogMode = 'add'
            this.modal = true
        },
        doSubmitAdd () { 
            this.closePopup()
            this.doSearch()
        },
        closePopup() { 
            this.modal = false
            this.dialogMode = 'none'
        },
        delAdmin (item) { 
            if(!item) { return }
            
            let va = this
            va.$store.dispatch('openConfirm', {
                message: item.admin_user_name + '을 일반관리자에서 삭제하시겠습니까?', 
                sub_message: '삭제 시 Admin사이트에 접속할 수 없습니다',
                va: va,
                okCb: function(va) { 
                    adminApi.delAdmin({admin_user_seqno: item.admin_user_seqno}, (va) => { 
                        // success 
                        va.$store.dispatch('openAlert', {
                            va: va,
                            message: '삭제가 완료되었습니다.',
                            closeCb: function(va) { 
                                va.doSearch()
                            }
                        })
                    }, (err, va) => { 
                        va.$store.dispatch('openAlert', {message: '삭제를 실패 했습니다.'})
                    }, va)        

                }
            })            
        },
        giveAdmin (item) { 
            if(!item) { return }

            let va = this
            va.$store.dispatch('openConfirm', {
                message: item.admin_user_name + '에게 대표관리자 권한을 위함하시겠습니까?', 
                sub_message: '위임 시 기존 대표관리자에서 일반관리자로 변경됩니다',
                va: va,
                okCb: function(va) { 
                    adminApi.giveAdmin({admin_user_seqno: item.admin_user_seqno}, (va) => { 
                        // success 
                        va.$store.dispatch('openAlert', {
                            va: va,
                            message: '대표관리자 권한위임이 완료되었습니다.',
                            closeCb: function(va) { 
                                va.$store.commit('login/setMainAdminYn', 'N')
                                va.doSearch()
                            }
                        })
                    }, (err, va) => { 
                        va.$store.dispatch('openAlert', {message: '대표관리자 권한위임을 실패 했습니다.'})
                    }, va)        

                }
            })             
        },
        doSearch() { 
            this.$store.dispatch('admin/getInfo', {
                scb: () => { 
                }, fcb: (err) => { 
                }, va: this
            })
        }
    }
}
</script>
